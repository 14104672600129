import $ from './app/jquery-shim.js';
import Utils from './app/utils.js';
import Accordion from './app/accordion.js';
import Anchor from './app/anchor.js';
import FixedHeader from './app/fixedheader.js';
import HeightLine from './app/heightline.js';
import ResponsiveTable from './app/responsive-table.js';
import Slidebar from './app/slidebar.js';
import Tab from './app/tab.js';
import CurrentNav from './app/current-nav.js';
import buildFormat from './app/format.js';
import OwlCarousel from 'owl.carousel';

import fontAwesome from "font-awesome/scss/font-awesome.scss"
import OwlCss from "owl.carousel/dist/assets/owl.carousel.css"
import OwlThemeCss from "owl.carousel/dist/assets/owl.theme.default.css"
import ScrollReveal from "scrollreveal"

class App {
  constructor() {
    this.Utils = new Utils();
    this.Accordion = new Accordion();
    this.Anchor = new Anchor();
    this.FixedHeader = new FixedHeader();
    this.HeightLine = new HeightLine();
    this.ResponsiveTable = new ResponsiveTable();
    this.Slidebar = new Slidebar();
    this.CurrentNav = new CurrentNav();
    this.Tab = new Tab();

      //SPメニューの高さ取得
    function menuHight() {
        var win = $(window).innerWidth();
        if (win > 750) {
            return false;
        }

        var $smpHeaderHeight = $('.l-header').height();
        var windowHeight = window.innerHeight;
        var winH = windowHeight - $smpHeaderHeight;

        console.log($smpHeaderHeight);

        //動かすターゲットを取得
        var targetSlidebarWrap = $('.c-slidebar-menu'),
            targetSlidebarMenu = $('.c-slidebar__parent'),
            targetSlidebarBtn = $('.c-slidebar-menu__parent');


        //いざ実行(クリックしたら処理を実行する)
        targetSlidebarBtn.on('click', function () {
            $('.c-slidebar-menu').toggleClass('is-active');

        });
    }


    // function spJump() {
    //     $('.js-link-sp').on('click',function() {
    //         $("body").removeClass("is-slidebar-active");
    //         // $(this).children(".l-footer__menutitle").toggleClass('is-open');
    //         // $(this).children(".l-footer__menulist.is-sub").slideToggle();
    //     })
    // }

      //フッターメニューSPスライド
      function menuSlide() {
          var win = $(window).innerWidth();
          if (win > 750) {
              return false;
          }
          $('.l-footer__block').on('click',function() {
              $(this).children(".l-footer__menutitle").toggleClass('is-open');
              $(this).children(".l-footer__menulist.is-sub").slideToggle();
          })
      }
      //owlcarousel
      function owlCarousel() {
          var owls = $('.owl-carousel');
          if (owls.length === 0) {
              return false
          }
          //->スライダー
          owls.imagesLoaded(function () {
              $('.c-main-visual__slider').owlCarousel({
                  items: 1,
                  margin: 0,
                  dots: true,
                  loop: true,
                  nav: false,
                  autoplayHoverPause: true,
                  autoplay: true,
                  autoplaySpeed: 500,
                  autoWidth: false,
                  autoHeight: false,
                  center: true,
                  navText: ['<img src="../assets/images/icon-slider-prev.svg" />','<img src="../assets/images/icon-slider-next.svg" />'],
              });
          });
          //->カルーセル
          owls.imagesLoaded(function () {
              $('.c-main-visual__carousel').owlCarousel({
                  items: 1,
                  margin: 16,
                  dots: true,
                  loop: true,
                  nav: true,
                  autoplayHoverPause: true,
                  autoplay: false,
                  autoplaySpeed: 500,
                  autoWidth: false,
                  autoHeight: false,
                  center: true,
                  navText: ['<div class="c-main-visual__nav is-left"><i class="fa fa-angle-left" aria-hidden="true"></i></div>','<div class="c-main-visual__nav is-right"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'],
                  responsive : {
                    // breakpoint from 0 up
                    0 : {
                      stagePadding: 32,
                    },
                    // breakpoint from 750  up
                    750 : {
                      stagePadding: 64,
                    },
                    // breakpoint from 950 up
                    950 : {
                      stagePadding: 156,
                    },
                    1400 : {
                      stagePadding: 312,
                    }
                  }
              });
          });
          //->カルーセル_ノーマル
          owls.imagesLoaded(function () {
          $('.c-use-image__slider-inner').owlCarousel({
            items: 1,
            margin: 70,
            dots: true,
            loop: true,
            nav: true,
            autoplayHoverPause: true,
            autoplay: true,
            autoplaySpeed: 500,
            autoWidth: false,
            autoHeight: false,
            center: true,
              stagePadding: 424,
            navText: ['<div class="c-use-image__nav is-left"><i class="fa fa-angle-left" aria-hidden="true"></i></div>','<div class="c-use-image__nav is-right"><i class="fa fa-angle-right" aria-hidden="true"></i></div>'],
            responsive : {
              // breakpoint from 0 up
              0 : {
                stagePadding: 32,
              },
              // breakpoint from 750  up
              750 : {
                stagePadding: 134,
              },
              // breakpoint from 950 up
              950 : {
                stagePadding: 226,
              },
              1400 : {
                stagePadding: 424,
              }
            }
          });
        });
          //->カルーセル_レイヤー
          owls.imagesLoaded(function () {
            $('.c-main-visual-layer__carousel').owlCarousel({
              items: 1,
              margin: 16,
              dots: true,
              loop: true,
              nav: false,
              autoplayHoverPause: true,
              autoplay: true,
              autoplaySpeed: 500,
              autoWidth: false,
              autoHeight: false,
              center: true,
              navText: ['<img src="..//assets/images/icon-slider-prev.svg" />','<img src="../assets/images/icon-slider-next.svg" />'],
            });
          });
          //->カード_カルーセル
          //スマホの時カルーセルをオフにする
          $(window).on('load', function () {
              var windowWidth = window.innerWidth;
              var $slider = $('.c-lineups__slider');
              var isCarousel = $slider.hasClass("js-card-slider owl-carousel");
              if (windowWidth <= 949) {
                  if (isCarousel == false) {
                      $slider.addClass("js-card-slider owl-carousel");
                  }
                  owls.imagesLoaded(function () {
                      $('.js-card-slider').owlCarousel({
                          margin: 16,
                          dots: false,
                          loop: true,
                          nav: true,
                          items: 3,
                          autoplayHoverPause: true,
                          autoplay: true,
                          autoplaySpeed: 500,
                          autoWidth: false,
                          autoHeight: false,
                          center: true,
                          navText: ['<img src="../assets/images/icon-slider-prev.svg" />','<img src="../assets/images/icon-slider-next.svg" />'],
                          responsive : {
                              // breakpoint from 0 up
                              0 : {
                                  items: 1,
                              },
                              550 : {
                                  items: 2,
                                  center: false,
                              },
                              // breakpoint from 750 up
                              780 : {
                                  items: 2,
                                  center: false,
                              },
                              949 : {
                                  items: 3
                              }
                          }
                      });
                  });
              } else {
                  $slider.removeClass("js-card-slider owl-carousel");

                  if (window.name != "off") {
                      // window.location.reload(true);
                      window.name = "off";
                  } else {
                      window.name = "";
                  }

              }
          });



      }

    $(function() {
        menuSlide();
        owlCarousel();
        // spJump();
    });
  }
}


(function ($) {

    var growApp = function () {
    };
    /*
     * userAgent取得
     * */
    var Agent = window.navigator.userAgent.toLowerCase();
    var _ua = (function (u) {
        return {
            Tablet: (u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1)
            || u.indexOf("ipad") != -1
            || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
            || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
            || u.indexOf("kindle") != -1
            || u.indexOf("silk") != -1
            || u.indexOf("playbook") != -1,
            Mobile: (u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
            || u.indexOf("iphone") != -1
            || u.indexOf("ipod") != -1
            || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
            || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
            || u.indexOf("blackberry") != -1
        }
    })(Agent);

    growApp.prototype.changeViewPort = function () {

        //ビューポート処理
        if (_ua.Mobile) {
            $('head').prepend('<meta name="viewport" content="width=device-width,initial-scale=1">');
        } else {
            $('head').prepend('<meta name="viewport" content="width=1100">');
        }
    };

    /*
    *
    * スマホメニューの高さを取得する
    *
    * */
    growApp.prototype.menuHight = function () {
        var win = $(window).innerWidth();
        if (win > 750) {
            return false;
        }

        var $smpHeaderHeight = $('.l-header').height();
        var windowHeight = window.innerHeight;
        var winH = windowHeight - $smpHeaderHeight;

        console.log($smpHeaderHeight);

        //動かすターゲットを取得
        var targetSlidebarWrap = $('.c-slidebar-menu'),
            targetSlidebarMenu = $('.c-slidebar__parent'),
            targetSlidebarBtn = $('.c-slidebar-menu__parent');


        //いざ実行(クリックしたら処理を実行する)
        targetSlidebarBtn.on('click', function () {
            $('.c-slidebar-menu').toggleClass('is-active');

        });
    }

    growApp.prototype.footerMenu = function () {

    }

    growApp.prototype.scrollreveal = function () {
        //- スクロールリーバル
        window.sr = ScrollReveal({duration: 600,mobile:false});
        function domEach(items, callback) {
            if (typeof items === "string") {
                var items = $(items);
            }
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                // callback = callback.bind(item)
                callback(i, item);
            }
        }

        //mainVisual
        sr.reveal(".js-fadein", {delay: 200, scale: 1, duration: 1400, origin: 'bottom', distance: '20px',}, 100);
        sr.reveal(".js-fadein.is-delay", {delay: 200, scale: 1, duration: 1400, origin: 'bottom', distance: '50px',}, 100);

        var blocks01 = $(".c-card__layout-outer");
        domEach(blocks01, function (key, item) {
            sr.reveal(item, {scale: 1,duration: 400,delay: 700}, 200);
            sr.reveal(item.children[1], {delay: 800, duration: 1400, scale: 1,origin: 'bottom', distance: '20px'}, 300);
            sr.reveal(item.children[2], {delay: 1200, duration: 1400, scale: 1,origin: 'bottom', distance: '20px'}, 300);
            sr.reveal(item.children[3], {delay: 1600, duration: 1400, scale: 1,origin: 'bottom', distance: '20px'}, 300);
        });
    }

    $(function () {
        var app = new growApp();
        app.changeViewPort();
        // app.menuHight();
        app.footerMenu();
        app.scrollreveal();
    });

})(jQuery);


window.GApp = new App();
